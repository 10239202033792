<template>
    <div class="tests-index-page">
        <div class="tests__tab">
            <el-radio-group v-model="tab" @change="switchTab">
                <el-radio-button label="tests">{{ $t('profile.tests-page.tests') }}</el-radio-button>
                <el-radio-button label="archive">{{ $t('profile.tests-page.archive') }}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="test-bank-wrapper" v-if="tab === 'tests'">
            <div class="clearfix w-100 padding-test">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-xl-2 col-lg-3">
                                <el-input v-model="filter.title" class="input-width-test-bank" :placeholder="$t('profile.test-bank.test')"></el-input>
                                <el-select
                                    v-model="filter.subject"
                                    clearable
                                    @change="getTopics($event, 'topics')"
                                    class="input-width-test-bank margin-top-test-bank"
                                    :placeholder="$t('profile.test-bank.subject')">
                                    <el-option v-for="(item, key) in subjects"
                                               :key="key"
                                               :label="item"
                                               :value="key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-xl-2 col-lg-3">
                                <el-select
                                    v-model="filter.topic"
                                    clearable
                                    class="input-width-test-bank"
                                    :placeholder="$t('profile.test-bank.theme')">
                                    <el-option v-for="item in topics"
                                               :key="item.id"
                                               :label="item.name"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select
                                    v-model="filter.complexity"
                                    clearable
                                    class="input-width-test-bank margin-top-test-bank"
                                    :placeholder="$t('profile.test.complexity')">
                                    <el-option v-for="(item, key) in complexities"
                                               :key="key"
                                               :label="item"
                                               :value="key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-xl-2 col-lg-3">
                                <div class="test-bank-btn-column">
                                    <el-button @click="resetFilter" class="btn-bg-white">{{ $t('profile.test-bank.clear') }}</el-button>
                                    <el-button :loading="filterLoading" @click="applyFilter" type="primary" class="btn-bg-purple margin-top-test-bank">{{ $t('profile.test-bank.apply') }}</el-button>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-3">
                                <el-button @click="$bvModal.show('modal-add-question')" type="danger" class="float-right mr-0 add-questions-btn" icon="el-icon-plus">{{ $t('profile.test-bank.add') }}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <el-button
                    @click="$bvModal.show('modal-add-question')"
                    class="test-bank-add-btn"
                    type="danger"
                    icon="el-icon-plus">
                    {{ $t('profile.test-bank.add') }}
                </el-button> -->
                <!--<el-button @click="$bvModal.show('modal-add-question')" type="danger" class="add-questions-btn" icon="el-icon-plus">{{ $t('profile.test-bank.add') }}</el-button>
                -->
            </div>
            <div class="padding-test-bank tests-table-block">
                <el-table
                    v-loading="loading"
                    class="tests-table"
                    :data="tests"
                    style="width: 100%">
                    <el-table-column
                        prop="title"
                        :label="$t('profile.test-bank.test')"
                        width="250">
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        :label="$t('profile.test-bank.subject')"
                        width="160">
                    </el-table-column>
                    <el-table-column
                        prop="topic"
                        :label="$t('profile.test-bank.theme')">
                    </el-table-column>
                    <el-table-column
                        prop="complexity"
                        width="200"
                        :label="$t('profile.test.complexity')">
                        <template slot-scope="scope">
                            {{ complexities[Object.keys(complexities)[scope.row.complexity-1]] }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        class="text-center"
                        label-class-name="text-center"
                        :label="$t('profile.test-bank.options')"
                        width="120">
                        <template slot-scope="scope">
                            <div class="actions">
                                <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.edit-btn')" placement="top">
                                    <el-button @click="$router.push({ name: 'test-update', params: { id: scope.row.id } })" type="default"><i class="edit-icon"></i></el-button>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.archive-btn')" placement="top">
                                    <el-button @click="showDeleteModal(scope.row.id)" type="default"><i class="delete-icon"></i></el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <b-pagination
                v-if="total > perPage"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                @change="getTests"
                last-number
                first-number
                hide-goto-end-buttons
                align="center"
                next-text=">"
                prev-text="<"
            ></b-pagination>
            <div class="test-wave"></div>

            <b-modal :id="'modal-delete-test'" @hidden="onCloseDeleteModal" hide-footer hide-header centered class="modal-add-hw" size="md">
                <div id="modal-wrapper">
                    <div id="dialog" class="py-3 modal-body-dialog">
                        <button @click="$bvModal.hide('modal-delete-test')" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"></span>
                        </button>
                        <h3 class="mb-3">{{ $t('profile.test.delete-modal-title') }}</h3>
                        <div class="clearfix pt-3 text-center">
                            <a @click="archive(modalDeleteTest)" class="btn btn-primary mx-2">{{ $t('profile.test.delete-confirm-btn') }}</a>
                            <a @click="$bvModal.hide('modal-delete-test')" class="btn btn-outline-primary mx-2">{{ $t('profile.test.delete-cancel-btn') }}</a>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal id="modal-add-question" @hidden="onCloseModal" hide-footer hide-header centered class="modal-add-hw" size="lg">
                <div v-show="!showAddQuestionsTab">
                    <div class="btn-back-ktp" @click="resetAddTest">
                        <div class="btn-back-arrow"></div>
                    </div>
                    <h3 class="modal-header-title">{{ $t('profile.test.create-title') }}</h3>
                    <el-button @click="showAddQuestionsTab = true" type="danger" class="add-questions-btn" icon="el-icon-plus" v-if="tabIndex">{{ $t('profile.test-bank.add') }}</el-button>
                    <b-tabs v-model="tabIndex" class="modal-add-question__tabs">
                        <b-tab :title="$t('profile.test.info')" active class="modal-add-question__tab">
                            <div class="modal-add-hw__content">
                                <div class="modal-add-hw__select">
                                    <form-group class="tests-form-group" :label="$t('profile.test.name-ru')" >
                                        <el-input v-model="test.title_ru" class="w-100" :placeholder="$t('profile.test.name-ru')" ></el-input>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test.name-kz')" >
                                        <el-input v-model="test.title_kz" class="w-100" :placeholder="$t('profile.test.name-kz')" ></el-input>
                                    </form-group>
                                    <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-ru')" >
                                        <quill-editor
                                            v-model="test.description_ru"
                                            :options="ruEditorOption"
                                            @ready="onEditorReady($event)"
                                        />
                                    </form-group>
                                    <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-kz')" >
                                        <quill-editor
                                            v-model="test.description_kz"
                                            :options="kzEditorOption"
                                            @ready="onEditorReady($event)"
                                        />
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test-bank.subject')">
                                        <el-select
                                            @change="getTopics($event, 'modalTopics')"
                                            v-model="test.subject_id"
                                            disabled
                                            class="w-100"
                                            :placeholder="$t('profile.test-bank.subject')" >
                                            <el-option v-for="(item, key) in subjects"
                                                       :key="key"
                                                       :label="item"
                                                       :value="key">
                                            </el-option>
                                        </el-select>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test-bank.theme')">
                                        <el-select v-model="test.topic_id" class="w-100" :placeholder="$t('profile.test-bank.theme')">
                                            <el-option v-for="item in modalTopics"
                                                       :key="item.id"
                                                       :label="item.name"
                                                       :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test.complexity')" >
                                        <el-select v-model="test.complexity" class="w-100" :placeholder="$t('profile.test.complexity')" >
                                            <el-option v-for="(item, key) in complexities"
                                                       :key="key"
                                                       :label="item"
                                                       :value="key">
                                            </el-option>
                                        </el-select>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test.number_of_passes')" >
                                        <el-select v-model="test.number_of_passes" class="w-100" :placeholder="$t('profile.test.number_of_passes')" >
                                            <el-option v-for="(item, key) in numberOfPasses"
                                                       :key="key"
                                                       :label="item"
                                                       :value="key">
                                            </el-option>
                                        </el-select>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test.show_right')" >
                                        <el-select v-model="test.show_right" class="w-100" :placeholder="$t('profile.test.show_right')" >
                                            <el-option :label="$t('profile.test.no')" :value="0"></el-option>
                                            <el-option :label="$t('profile.test.yes')" :value="1"></el-option>
                                        </el-select>
                                    </form-group>
                                    <form-group class="tests-form-group" :label="$t('profile.test.show_comments')" >
                                        <el-select v-model="test.show_comments" class="w-100" :placeholder="$t('profile.test.show_comments')" >
                                            <el-option :label="$t('profile.test.no')" :value="0"></el-option>
                                            <el-option :label="$t('profile.test.yes')" :value="1"></el-option>
                                        </el-select>
                                    </form-group>
                                </div>
                            </div>
                            <div class="modal-add-hw__footer-btns">
                                <div class="d-flex flex-row">
                                    <el-button @click="addTest" class="modal-add-hw__save">
                                        {{ $t('profile.test-bank.save') }}
                                    </el-button>
                                    <el-button class="modal-add-hw__cancel" @click="resetAddTest">
                                        {{ $t('profile.test-bank.reset') }}
                                    </el-button>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('profile.test.questions')" class="modal-add-question__tab pb-3">
                            <div class="tests-table-block">
                                <el-table
                                    v-loading="loading"
                                    class="tests-table"
                                    :data="test.questions"
                                    style="width: 100%">
                                    <el-table-column
                                        prop="id"
                                        :label="$t('profile.test-bank.id')"
                                        width="70">
                                    </el-table-column>
                                    <el-table-column
                                        prop="title"
                                        :label="$t('profile.test-bank.question')"
                                        width="370">
                                    </el-table-column>
                                    <el-table-column
                                        prop="subject"
                                        width="200"
                                        :label="$t('profile.test-bank.subject')">
                                    </el-table-column>
                                    <el-table-column
                                        prop="topic"
                                        width="240"
                                        :label="$t('profile.test-bank.theme')">
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        class="text-center"
                                        label-class-name="text-center pl-1"
                                        :label="$t('profile.test-bank.options')"
                                        width="100">
                                        <template slot-scope="scope">
                                            <div class="actions text-center">
                                                <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.delete-btn')" placement="top">
                                                    <el-button type="danger" @click="deleteQuestion(scope.row.id)"><i class="remove-icon"></i></el-button>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <b-pagination
                                v-if="total > perPage"
                                v-model="currentPage"
                                :total-rows="total"
                                :per-page="perPage"
                                last-number
                                first-number
                                hide-goto-end-buttons
                                align="center"
                                next-text=">"
                                prev-text="<"
                            ></b-pagination>
                            <div class="test-bank-btns-bottom">
                                <el-button class="test-bank-btn-save" @click="addTest">
                                    {{ $t('profile.test-bank.save') }}
                                </el-button>
                                <el-button class="test-bank-btn-cancel" @click="resetAddTest">
                                    {{ $t('profile.test-bank.reset') }}
                                </el-button>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div v-show="showAddQuestionsTab">
                    <i class="el-icon-close cursor-pointer float-right" @click="showAddQuestionsTab = false"></i>
                    <AddQuestions :test-questions="test.questions" :quiz-id="test.id" :show-tab="showAddQuestionsTab" @close="onCloseTab" />
                </div>
            </b-modal>
        </div>
        <div class="test-bank-wrapper" v-if="tab === 'archive'">
            <div class="clearfix w-100 padding-test">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-xl-2 col-lg-3">
                                <el-input v-model="filter.title" class="input-width-test-bank" :placeholder="$t('profile.test-bank.test')"></el-input>
                                <el-select
                                    v-model="filter.subject"
                                    clearable
                                    @change="getTopics($event, 'topics')"
                                    class="input-width-test-bank margin-top-test-bank"
                                    :placeholder="$t('profile.test-bank.subject')">
                                    <el-option v-for="(item, key) in subjects"
                                               :key="key"
                                               :label="item"
                                               :value="key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-xl-2 col-lg-3">
                                <el-select
                                    v-model="filter.topic"
                                    clearable
                                    class="input-width-test-bank"
                                    :placeholder="$t('profile.test-bank.theme')">
                                    <el-option v-for="item in topics"
                                               :key="item.id"
                                               :label="item.name"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select
                                    v-model="filter.complexity"
                                    clearable
                                    class="input-width-test-bank margin-top-test-bank"
                                    :placeholder="$t('profile.test.complexity')">
                                    <el-option v-for="(item, key) in complexities"
                                               :key="key"
                                               :label="item"
                                               :value="key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-xl-2 col-lg-3">
                                <div class="test-bank-btn-column">
                                    <el-button @click="resetFilter" class="btn-bg-white">{{ $t('profile.test-bank.clear') }}</el-button>
                                    <el-button :loading="filterLoading" @click="applyFilter" type="primary" class="btn-bg-purple margin-top-test-bank">{{ $t('profile.test-bank.apply') }}</el-button>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-3">
                                <el-button @click="$bvModal.show('modal-add-question')" type="danger" class="float-right mr-0 add-questions-btn" icon="el-icon-plus">{{ $t('profile.test-bank.add') }}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="padding-test-bank tests-table-block">
                <el-table
                    v-loading="loading"
                    class="tests-table"
                    :data="archived"
                    style="width: 100%">
                    <el-table-column
                        prop="title"
                        :label="$t('profile.test-bank.test')"
                        width="250">
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        :label="$t('profile.test-bank.subject')"
                        width="160">
                    </el-table-column>
                    <el-table-column
                        prop="topic"
                        :label="$t('profile.test-bank.theme')">
                    </el-table-column>
                    <el-table-column
                        prop="complexity"
                        width="200"
                        :label="$t('profile.test.complexity')">
                        <template slot-scope="scope">
                            {{ complexities[Object.keys(complexities)[scope.row.complexity-1]] }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        class="text-center"
                        label-class-name="text-center"
                        :label="$t('profile.test-bank.options')"
                        width="120">
                        <template slot-scope="scope">
                            <div class="actions">
                                <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.edit-btn')" placement="top">
                                    <el-button @click="$router.push({ name: 'test-update', params: { id: scope.row.id } })" type="default"><i class="edit-icon"></i></el-button>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.restore-btn')" placement="top">
                                    <el-button type="default" @click="showRestoreModal(scope.row.id)"><i class="restore-icon"></i></el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <b-pagination
                v-if="archivedTotal > archivedPerPage"
                v-model="archivedCurrentPage"
                :total-rows="archivedTotal"
                :per-page="archivedPerPage"
                @change="getArchived"
                last-number
                first-number
                hide-goto-end-buttons
                align="center"
                next-text=">"
                prev-text="<"
            ></b-pagination>
            <div class="test-wave"></div>

            <b-modal :id="'modal-restore-test'" @hidden="onCloseRestoreModal" hide-footer hide-header centered class="modal-add-hw" size="md">
                <div id="restore-modal-wrapper">
                    <div id="restore-dialog" class="py-3 modal-body-dialog">
                        <button @click="$bvModal.hide('modal-restore-test')" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"></span>
                        </button>
                        <h3 class="mb-3">{{ $t('profile.test.restore-modal-title') }}</h3>
                        <div class="clearfix pt-3 text-center">
                            <a @click="restore(modalRestoreTest)" class="btn btn-primary mx-2">{{ $t('profile.test.restore-confirm-btn') }}</a>
                            <a @click="$bvModal.hide('modal-restore-test')" class="btn btn-outline-primary mx-2">{{ $t('profile.test.restore-cancel-btn') }}</a>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Vue from "vue"
import AddQuestions from "../../components/AddQuestions"
import { quillEditor } from "vue-quill-editor"

export default {
    components: { AddQuestions, quillEditor },
    data() {
        return {
            ruEditorOption: {
                placeholder: this.$t('profile.test.desc-ru'),
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            kzEditorOption: {
                placeholder: this.$t('profile.test.desc-kz'),
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            loading: false,
            filterLoading: false,
            showAddQuestionsTab: false,
            tab: 'tests',
            tabIndex: 0,
            total: 0,
            perPage: 0,
            currentPage: 1,
            archivedTotal: 0,
            archivedPerPage: 0,
            archivedCurrentPage: 1,
            filter: {
                title: '',
                subject: '',
                topic: '',
                complexity: '',
            },
            test: {
                title_ru: '',
                title_kz: '',
                subject_id: '',
                topic_id: '',
                complexity: '',
                number_of_passes: '',
                description_ru: '',
                description_kz: '',
                questions: []
            },
            tests: [],
            archived: [],
            subjects: {},
            topics: {},
            modalTopics: {},
            complexities: {
                1: 'Легкий',
                2: 'Средний',
                3: 'Сложный'
            },
            numberOfPasses: {
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
            },
            questions: [],
            questionFilter: {
                title: '',
                subject: '',
                topic: '',
                complexity: '',
            },
            questionsTotal: 0,
            questionsPerPage: 0,
            questionsCurrentPage: 1,
            modalDeleteTest: 0,
            modalRestoreTest: 0
        }
    },
    mounted() {
        this.getTests(1)
    },
    methods: {
        getTests(page) {
            let title = this.filter.title
            let subjectId = this.filter.subject
            let topic = this.filter.topic
            let complexity = this.filter.complexity
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/quiz?page=${page}&subject_id=${subjectId}&topic_id=${topic}&title=${title}&complexity=${complexity}`)
                .then((res) => {
                    this.tests = res.body.data
                    this.test.subject_id = res.body.subject
                    this.subjects = res.body.subjects
                    // this.topics = res.body.topics
                    this.total = res.body.meta.total
                    this.perPage = res.body.meta.per_page
                    this.filterLoading = false
                    this.loading = false
                })
                .catch(() => {
                    this.filterLoading = false
                    this.loading = false
                })
        },
        switchTab(tab) {
            if (tab === 'archive') {
                this.resetFilter()
                this.getArchived(this.archivedCurrentPage)
            }
        },
        archive(id) {
            this.$http.post(`${window.API_ROOT}/api/quiz/archive/${id}`)
                .then((res) => {
                    if (res.body.status === 'success') {
                        this.tests = res.body.data
                        this.perPage = res.body.meta.per_page
                        this.currentPage = res.body.meta.current_page
                        this.resetFilter()
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест удален',
                            type: 'success'
                        });
                    }
                    this.$bvModal.hide('modal-delete-test');
                })
                .catch(() => {
                    this.$bvModal.hide('modal-delete-test');
                })
        },
        restore(id) {
            this.$http.post(`${window.API_ROOT}/api/quiz/restore/${id}`)
                .then((res) => {
                    if (res.body.status === 'success') {
                        this.archived = res.body.data
                        this.archivedPerPage = res.body.meta.per_page
                        this.archivedCurrentPage = res.body.meta.current_page
                        this.resetFilter()
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест восстановлен',
                            type: 'success'
                        });
                    }
                    this.$bvModal.hide('modal-restore-test');
                })
                .catch(() => {
                    this.$bvModal.hide('modal-restore-test');
                })
        },
        getArchived(page) {
            let title = this.filter.title
            let subjectId = this.filter.subject
            let topic = this.filter.topic
            let complexity = this.filter.complexity
            this.$http.get(`${window.API_ROOT}/api/quiz/archived?page=${page}&subject_id=${subjectId}&topic_id=${topic}&title=${title}&complexity=${complexity}`)
                .then((res) => {
                    this.archived = res.body.data
                    this.subjects = res.body.subjects
                    // this.topics = res.body.topics
                    this.archivedTotal = res.body.meta.total
                    this.archivedPerPage = res.body.meta.per_page
                    this.filterLoading = false
                    this.loading = false
                })
                .catch(() => {
                    this.filterLoading = false
                    this.loading = false
                })
        },
        addTest() {
            this.$http.post(`${window.API_ROOT}/api/quiz/create`, this.test)
                .then((res) => {
                    if (res.body.status === 'success') {
                        this.resetAddTest()
                        window.location.reload()
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест сохранен',
                            type: 'success'
                        });
                    }
                })
                .catch(() => {
                })
        },
        deleteQuestion(id) {
            this.test.questions.forEach((question, key) => {
                if (question.id === id) {
                    this.test.questions.splice(key, 1);
                }
            })
        },
        getTopics(subjectId, model) {
            this.filter.topic = '';
            this.test.topic_id = '';
            this[model] = [];
            this.$http.get(`${window.API_ROOT}/api/reference/topics/${subjectId}`)
                .then((res) => {
                    this[model] = res.body.data
                })
                .catch(() => {
                })
        },
        resetAddTest() {
            this.test = {
                title_ru: '',
                title_kz: '',
                subject_id: '',
                topic_id: '',
                complexity: '',
                number_of_passes: '',
                description_ru: '',
                description_kz: ''
            };
            this.$bvModal.hide('modal-add-question')
        },
        applyFilter() {
            this.filterLoading = true;
            this.currentPage = 1
            this.getTests(this.currentPage)
        },
        resetFilter() {
            this.filter.title = '';
            this.filter.subject = '';
            this.filter.topic = '';
            this.filter.complexity = '';
            this.currentPage = 1
            this.getTests(this.currentPage);
        },
        onCloseTab() {
            this.showAddQuestionsTab = false
        },
        onEditorReady(quill) {
            quill.enable(false)
            quill.on('selection-change', function () {
                quill.enable(true)
            })
        },
        onCloseModal() {
            this.showAddQuestionsTab = false;
            this.resetAddTest()
        },
        onCloseDeleteModal() {
            this.modalDeleteTest = 0;
        },
        onCloseRestoreModal() {
            this.modalRestoreTest = 0;
        },
        showDeleteModal(id) {
            this.modalDeleteTest = id;
            this.$bvModal.show('modal-delete-test');
        },
        showRestoreModal(id) {
            this.modalRestoreTest = id;
            this.$bvModal.show('modal-restore-test');
        }
    }
}
</script>
<style lang="less" scoped>
.tests-wrap {
    padding-right: 30px;
}
.modal .modal-body {
    padding: 2rem;
}

.modal .modal-body-dialog button.close {
    border-radius: 44px;
    width: 44px;
    height: 44px;
    background-color: #B5B5B5;
    background-image: url('/images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    transition: all 0.2s ease;
}

.modal .modal-body-dialog button.close:hover {
    background-color: #8a8a8a;
    transition: all 0.2s ease;
}

.modal .modal-body-dialog button.close:focus,
.modal .modal-body-dialog button.close:active {
    outline: none;
}

.modal {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    text-align: center;
    box-sizing: border-box;
}


.modal .modal-body-dialog {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    position: relative;
    width: 100%;
}

.modal .modal-body-dialog h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 10px;
    padding: 0;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
}

.modal .modal-body-dialog span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.modal .modal-body-dialog .display-block {
    display: block;
}

.modal .modal-body-dialog .btn.btn-primary,
.modal .modal-body-dialog .btn.btn-outline-primary{
    border: none;
    border-radius: 4px;
}

.modal .modal-body-dialog p {
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.modal .modal-body-dialog #form {
    max-width: 300px;
    margin: 25px auto 0;
}

.modal .modal-body-dialog #form button {
    margin: 30px 0 50px;
    width: 100%;
    padding: 6px;
    background-color: #b85fc6;
    border: none;
    text-transform: uppercase;
}

.modal .modal-body-dialog div {
    position: relative;
    z-index: 1;
}

.modal .modal-body-dialog img {
    position: absolute;
    bottom: -70px;
    right: -63px;
}

.modal .modal-body-dialog img.warning-icon {
    position: initial;
}
/deep/ .el-table::before,
/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before{
    content: none;
}
/deep/ .el-table__body tr.hover-row.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped>td,
/deep/ .el-table__body tr.hover-row>td{
    background-color: initial;
}
/deep/ .el-table {
    margin-top: 2rem;
    tbody {
        td {
            border: none !important;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
            div{
                padding: 0;
            }
        }
    }
    th {
        border: none !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        color: #473F95;
        border-radius: 4px;
        padding-right: 5px;
        div {
            border-radius: 4px;
            padding: 8px;
            background: #E3E2F2;
        }
    }
}
.btn-back-ktp {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 58px;
    width: 58px;
    background: #EEEAFB;
    border-radius: 5px 0;
    display: flex;
    justify-content: center;
    float: left;
    margin-top: -21px;
    margin-left: -16px;
}

.test-bank-add-btn {
    float: right;
    background: #D23168;
    border-color: #D23168;
    font-family: 'Roboto', sans-serif;
}
.add-questions-btn {
    float: right;
    margin-top: 25px;
    margin-right: 40px;
    background: #D23168;
    border-color: #D23168;
    font-family: 'Roboto', sans-serif;
}
.add-questions-btn i{
    font-weight: bold;
}
.btn-back-arrow {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 58px;
    width: 58px;
    border-radius: 5px 0;
    display: flex;
    justify-content: center;
    background: #EEEAFB url("/images/arrow-left.svg") no-repeat center;
}
.modal-header-title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #473F95;
    margin: 5px 0;
}
.modal-add-question__tabs {
    padding: 20px 0;
}
.el-icon-close {
    background: url("/images/profile/close-lila.svg") no-repeat center;
    text-indent: -9999px;
    display: block;
    width: 24px;
    height: 24px;
}
.test-wave{
    margin-top: 2rem;
    height: 90px;
    width:100%;
    background-image: url("/images/footer-wave-ktp.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.tests-table{
    margin-top: 4rem;
    width:100%;
    thead{
        td{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 35px;
            color: #473F95;

            border-radius: 4px;
            padding-right: 5px;

            div{
                border-radius: 4px;
                padding-left: 8px;
                padding-right: 8px;
                background: #E3E2F2;
            }
        }
    }
    tbody{
        td{
            padding-top: 20px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
        }
        td:last-child {
            padding-right: 0;
        }
    }
}
.tests-table.questions-table {
    thead {
        td:nth-child(1) {
            min-width: 50px;
        }
        td:nth-child(2) {
            min-width: 300px;
        }
        td:nth-child(3) {
            min-width: 100px;
        }
        td:nth-child(4) {
            min-width: 100px;
        }
        td:nth-child(5) {
            min-width: 100px;
        }
    }
    tbody {
        td:nth-child(1) {
            min-width: 50px;
        }
        td:nth-child(2) {
            min-width: 300px;
        }
        td:nth-child(3) {
            min-width: 100px;
        }
        td:nth-child(4) {
            min-width: 100px;
        }
        td:nth-child(5) {
            min-width: 100px;
        }
    }
}
.tests-table .actions .el-button{
    padding: 6px;
    border: none;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: transparent;
}
.tests-table .actions .el-button:focus {
    background-color: transparent;
}
.tests-table .actions .el-button.el-button--default:hover{
    background: #ecf5ff;
}
.tests-table .actions .edit-icon{
    background: url("/images/profile/edit.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.tests-table .actions .delete-icon{
    background: url("/images/profile/archive.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.tests-table .actions .restore-icon{
    background: url("/images/profile/restore.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.tests-table .actions .remove-icon{
    background: url("/images/profile/delete.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.test-bank-wrapper .btn-red{
    margin-left: auto;
    max-height: 40px;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width:150px;
    color: white;
    background-color: #D23168;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 42px;
    span{
        padding-right: 10px;
        font-weight: bold;
        font-size: 20px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.margin-top-test-bank{
    margin-top: 20px;
}
.test-bank-header-right-column{
    margin-right: 30px;

}
.test-bank-header-left-column{
    margin-right: 30px;
}
.btn-bg-white{
    width: 150px;
}
.btn-bg-purple{
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width: 150px;
    color: white;
    background-color: #473F95;
    border: 1px solid #473F95;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0;

    span {
        padding-right: 10px;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.btn-purple {
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width: 150px;
    color: #473F95;
    line-height: 40px;
    background-color: white;
    border: 1px solid #473F95;
    text-align: center;
    display: flex;
    justify-content: center;

    span {
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}

.input-width-test-bank {
    width: 100%
}
.test-bank-header{
    display: flex;
}
.padding-test-bank{
    padding-right:30px;
    padding-left: 30px;
}
.test-bank-wrapper {
    padding-top: 30px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}
.test-bank-columns {
    display: flex;
}
.tests-table-block {
    overflow-x: auto;
}
.pagination {
    border: none;
    box-shadow: none;
    padding-top: 40px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
.test-bank-btns-bottom {
    margin-top: 60px;
    text-align: center;
}

.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .nav-tabs {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: none;
}
/deep/ .nav-tabs .nav-link {
    min-width: 59px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #473F95;
    border: none;
    border-bottom: 3px solid transparent;
}
/deep/ .nav-tabs .nav-link.active{
    border-bottom: 3px solid #473F95;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tab-content > .active {
    border: none;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.el-checkbox {
    color: #473F95;
}
.el-checkbox__input.is-checked+.el-checkbox__label,
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}

.tests-form-group__delete {
    color: #D23168;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border: none;
    background-color: transparent;
    padding: 0;
}
.tests-index-page {
    color: #303030;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    margin: 0 0 114px 0;
    max-width: 100%;
}
.tests__tab {
    line-height: 36px;
    text-align: center;
    display: flex;
    margin: 0 auto 5rem;
    width: 600px;
}
.el-radio-group {
    width: 100%;
}
.el-radio-group .el-radio-button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    width: 33%;
}
/deep/ .el-radio-button__inner{
    width: 100%;
    background: none;
    color: #473F95;
    border-color: #473F95;
}
/deep/ .is-active .el-radio-button__inner:hover {
    color: #fff !important;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner{
    border-color: #473F95
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #473F95;
    border-color: #473F95;
    box-shadow: -1px 0 0 0 #473f95;
}
/deep/ .el-radio-button__inner:hover {
    color: #473F95;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 5px 0 0 5px;
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 5px 5px 0;
}
.subject-width{
    width:200px
}
.theme-width{
    width:218px
}
.padding-test{
    padding-left: 30px;
    padding-right: 30px;
}
.test-wave{
    margin-top: 2rem;
    height: 90px;
    width:100%;
    background-image: url("/images/footer-wave-ktp.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.tests-form-group{
    color: #473F95;
    margin-top: 20px;
    padding-left: 38px;
    padding-right: 38px;
}
.tests-table{
    margin-top: 27px;
    width:100%;
    thead{
        td{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 35px;
            color: #473F95;

            border-radius: 4px;
            padding-right: 5px;

            div {
                border-radius: 4px;
                padding-left: 8px;
                background: #E3E2F2;
            }
        }
        td:last-child {
            padding-right: 0;
        }
    }

    tbody{
        td{
            padding-top: 20px;
            max-width: 370px;
            min-width: 66px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-right: 5px;
            padding-left: 8px;
        }
        td:last-child {
            padding-right: 0;
        }
    }
}
.tests-table thead .tests-table__header:nth-child(1),
.tests-table tbody td:nth-child(1){
    min-width: 200px;
}
.tests-table thead .tests-table__header:nth-child(2),
.tests-table tbody td:nth-child(2){
    min-width: 60px;
}
.tests-table thead .tests-table__header:nth-child(3),
.tests-table tbody td:nth-child(3){
    min-width: 100px;
}
.tests-table thead .tests-table__header:nth-child(4),
.tests-table tbody td:nth-child(4){
    min-width: 100px;
}
.tests-table thead .tests-table__header:nth-child(5),
.tests-table tbody td:nth-child(5){
    min-width: 100px;
}

.tests-table.questions-table thead .tests-table__header:nth-child(1),
.tests-table.questions-table tbody td:nth-child(1){
    min-width: 50px;
}
.tests-table.questions-table thead .tests-table__header:nth-child(2),
.tests-table.questions-table tbody td:nth-child(2){
    min-width: 300px;
}
.tests-table.questions-table thead .tests-table__header:nth-child(3),
.tests-table.questions-table tbody td:nth-child(3){
    min-width: 100px;
}
.tests-table.questions-table thead .tests-table__header:nth-child(4),
.tests-table.questions-table tbody td:nth-child(4){
    min-width: 100px;
}
.tests-table.questions-table thead .tests-table__header:nth-child(5),
.tests-table.questions-table tbody td:nth-child(5){
    min-width: 100px;
}
.btn-red{
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width:150px;
    color: white;
    line-height:46px;
    background-color: #D23168;
    text-align: center;
    display: flex;
    justify-content: center;
    span {
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.btn-purple{
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width:150px;
    color: #473F95;
    background-color: #EEEAFB;
    text-align: center;
    display: flex;
    justify-content: center;
    span{
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.test-header-group{
    display: flex;
}
.test-header-btn{
    min-width:59px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    user-select: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #473F95;
}
.test-header-btn-active{
    border-bottom: 3px solid #473F95;
}
.test-header{
    display: flex;
    justify-content:space-between;
}
.test-content{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding-top: 38px;
    max-width:880px;
    width: -webkit-fill-available;
    background-color: white;
    margin: 0 auto;
}
.tests-table-block {
    overflow-x: auto;
    margin: 0;
    padding: 0 30px;
    width: 100%;
}
.pagination {
    border: none;
    box-shadow: none;
    padding-top: 40px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
.test-bank-btns-bottom {
    margin-top: 38px;
    text-align: center;
    justify-content: center;
    margin-bottom: 64px;
}
.test-bank-btn-save {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #EEEAFB;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    margin-right: 10px;
}
.test-bank-btn-cancel {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.modal-add-hw__close {
    background-color: transparent;
    border-radius: 0;
    min-width: auto;
    width: fit-content;
    padding: 0;
    border: none;
    margin-left: auto;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.modal-add-hw__select {
    padding-bottom: 13px;
}
.modal-add-hw__select-btns {
    padding-top: 19px;
    padding-bottom: 67px;
}
.modal-add-hw__apply {
    background: #EEEAFB;
    border-radius: 5px;
    margin-right: 10px;
    color: #473F95;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    padding: 14px 36px;
}
.modal-add-hw__reset {
    color: #fff;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.cursor-pointer {
    cursor: pointer;
    display: block;
}
.add-hw-table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: separate;
}
.add-hw-table-wrapper.loading {
    position: relative;
}
.add-hw-table-wrapper.loading::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-color: #ffffff9e;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.add-hw-table__header {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    border-radius: 4px;
    background: #E3E2F2;
}
.add-hw-table__check-box {
    width: 48px;
    text-align: center;
}
.add-hw-table__theme {
    width: 309px;
}
.add-hw-table__subject {
    width: 196px;
}
.add-hw-table__task-type {
    width: 242px;
}
.add-hw-table__theme,
.add-hw-table__subject,
.add-hw-table__task-type {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
}
.add-hw-table__theme > div,
.add-hw-table__subject > div,
.add-hw-table__task-type > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
/deep/ .el-checkbox__inner:hover {
    border-color: #473F95;
}
/deep/ .el-checkbox__inner {
    width: 19px;
    height: 19px;
}
/deep/ .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 2px;
    width: 4px;
}
.modal-add-hw__footer {
    text-align: center;
    margin-bottom: 18px;
}
.modal-add-hw__cancel {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
    background-color: transparent;
    padding: 13px 46px;
}
.modal-add-hw__save {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    background: #473F95;
    border-radius: 5px;
    padding: 13px 35px;
}
/deep/ #modal-add-question .modal-body {
    position: relative;
    background-image: url("/images/profile/modal-body-wave.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.modal-add-hw__footer-btns {
    text-align: center;
    padding: 0 38px;
    margin-bottom: 64px;
}
.tests-index-page > .test-bank-btns-bottom {
    margin-top: 60px;
}
/deep/ .el-table th>.cell {
    white-space: nowrap;
}
@media (max-width: 1400px) {
    .tests-index-page {
        width: 100%;
        max-width: none;
    }
    .test-bank-wrapper {
        width: 100%;
    }
    .test-bank-columns {
        flex-direction: column;
    }
    .test-bank-add-btn {
        float: left;
    }
    .test-bank-header-left-column, .test-bank-header-right-column  {
        margin-bottom: 20px;
    }
}
@media (max-width: 991px) {
    .test-content {
        box-shadow: none;
        max-width: none;
        width: 100%;
        padding-top: 0;
    }
    .padding-test {
        padding: 0;
    }
    .tests-table-block {
        margin-right: 0;
        margin-left: 0;
    }
    .test-header {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
    }
    .test-header .btn-red {
        width: 100%;
    }
    .test-header-group {
        margin-bottom: 32px;
    }
    .test-header-group .test-header-btn:first-child {
        margin-right: 8px;
    }
    .test-header-group .test-header-btn:last-child {
        width: 135px;
        justify-content: center;
    }
    .test-header-btn {
        color: #473F95;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        padding: 9px 14px;
        background: #FDFEFF;
        border: 1px solid #473F95;
        box-sizing: border-box;
        backdrop-filter: blur(20px);
        border-radius: 12px;
    }
    .test-header-btn-active {
        color: #FFFFFF;
        background: #473F95;
        border: 1px solid #473F95;
    }
    .test-wave {
        display: none;
    }
    .btn-red--hide {
        display: none;
    }
    .tests-form-group {
        padding: 0;
    }
    /deep/ .page-item.active .page-link {
        z-index: 0;
    }
    .modal-add-hw__footer-btns {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    .modal-add-hw__footer-btns .modal-add-hw__save,
    .modal-add-hw__footer-btns .modal-add-hw__cancel {
        padding: 13px 24px;
    }
    .modal-add-hw__select-btns {
        text-align: center;
    }
    .add-hw-table-wrapper {
        overflow-x: auto;
    }
    #modal-add-hw .pagination {
        padding-top: 28px;
    }
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .test-bank-wrapper {
        width: 100%;
    }
    /deep/ .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 5px;
        border: 1px solid #473F95;
    }
    /deep/ .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 5px;
        border: 1px solid #473F95;
    }
    /deep/ .el-radio-group .el-radio-button:first-child {
        margin-right: 8px;
    }
    .el-radio-group .el-radio-button,
    .tests__tab {
        width: auto;
    }
    #modal-add-question .add-questions-btn {
        float: none;
        width: 100%;
    }
    .tests-index-page {
        margin-bottom: 0;
    }
    .tests-wrap {
        padding-right: 0px;
    }
    .test-bank-wrapper {
        box-shadow: none;
    }
    .padding-test-bank {
        padding-right: 0px;
        padding-left: 0px;
    }
    .tests__tab {
        margin-bottom: 0px!important;
    }
}
@media (max-width: 767px) {
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .test-bank-add-btn {
        margin-left: 14px;
        margin-top: 32px;
    }
    .padding-test-bank {
        width: 100%;
    }
    .input-width-test-bank,
    .test-bank-columns {
        width: 100%;
    }
    .test-bank-header-left-column {
        margin-right: 0;
    }
    .test-bank-btn-column {
        display: flex;
        justify-content: center;
    }
    .test-bank-btn-column > button:first-child {
        margin-right: 12px;
    }
    .test-bank-btn-column > button {
        width: 150px;
        height: 45px;
        margin-top: 0;
        align-items: center;
    }
    .tests__tab .el-radio-group {
        display: flex;
        overflow-x: auto;
    }
    .tests__tab {
        width: 100%;
    }
    .el-radio-group .el-radio-button {
        width: 100%;
    }
    .add-questions-btn {
        float: none;
        width: 100%;
        margin-top: 32px;
    }
}

</style>